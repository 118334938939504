import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import styled from "styled-components";
import './whitepaper.css'

import { Link } from 'react-router-dom';

const Footer = styled.div`
  display: flex;
`;

const Nav = styled.div`
display:flex;
justify-content:center;
background-color: rgba(0, 0, 0, 0.527);
max-width:100%;
padding-top: 8px;
padding-bottom: 8px;
padding-left: 20px;
padding-right: 20px;
`;
const Menu = styled.div`
display:flex;
jsutify-content: center;
align-items:center;
`;
const Main = styled.div`
display:flex;
justify-content: center;
align-items:center;
background-color: white;
position:relative;
height:100%;
width:100%;
flex-direction: column;
padding-top: 50px;
color: black;
overflow-x:hidden;
`;

function WhitePaper() {
    return (

        <div>
            <Nav>
                <Menu className="menu mobile-center">
                    <Link to="/"><img className="galerielogo" src="./logo.png" alt="" /></Link>
                    <div className="socials mobile-display">
                        <a href="https://discord.com/invite/9uX2wbbzG9" target="_blank"><img className="sociallogo" src="./discord.png" alt="" /></a>
                        <a href="https://twitter.com/lagaleriedesnft" target="_blank"><img className="sociallogo" src="./twitter.png" alt="" /></a>
                        <a href="https://twitter.com/lagaleriedesnft" target="_blank"><img className="sociallogo" src="./insta2.png" alt="" /></a>
                    </div>
                </Menu>

            </Nav>
            <Main className='main'>
                <div>

                    <img className='mfblogo2' src='./mfblogoblack.png'></img>
                </div>
                <h1 className='bold'>
                    WHITE PAPER
                </h1>
                <img className='line' src='./line.png'></img>
                <p className='title'>WHY ARE WE DIFFERENT?</p>
                <div className='text-box-wp'>
                    <p>Most NFT collections make promises of what they will do with the funds gathered,<br />
                        and in 99% of times said projects fail to deliver on those promises.<br />
                        The 1% of ones that don't take their time to start developing what they promise. <br /> <br />
                        We do not. We are not afraid of putting our money where our mouths are.<br />
                        We invest upfront from our funds to develop on what we promise to our holders. </p>
                </div>
                <img className='line' src='./line.png'></img>
                <p className='title'>WHY DO WE DO IT?</p>
                <div className='text-box-wp'>
                    <p>As we are all sitting in a bear market, now more than ever we have to showcase<br />
                        to the web3 space that we are builders by building.<br /><br />
                        After 2 weeks of the MFB Mint we are fulfilling what we have promised - Launching HODL.<br /><br />
                        We are not dependant upon a sell out to do what is in the white paper and the roadmap.<br />
                        Those that come along will help us accelerate the process launched, and will be<br />
                        rewarded generously for their support. </p>
                </div>
                <img className='line' src='./line.png'></img>
                <p className='title'>WHAT IS META “FOKIN” BLINDERS?</p>
                <div className='text-box-wp'>
                    <p>It is the second NFT collection from La Galerie that will grant you access to<br />
                        LG DAO and a free HODL NFT.  </p>
                </div>
                <img className='line' src='./line.png'></img>
                <p className='title'>WHAT IS HODL?</p>
                <img className='hodllogowp' src='./hodlwplogo.png'></img>
                <div className='text-box-wp'>
                    <p>HODL is a web3 social media platform that will give you the ability to speak freely again.<br />
                        A mix between well-known social medias such as Twitter, instagram, telegram,<br />
                        onlyfans and patreon.<br /><br />
                        EVERYTHING AT THE SAME TIME IN THE SAME APP.<br /><br />
                        You don’t need to switch from app to app, log-in and out, when you can do and access<br />
                        everything on HODL, all things web2 and web3 related at the palm of your hand.<br /><br />
                        Are you tired of not having a real voice on social medias?<br /><br />
                        Are you tired of deleted posts and accounts banned just because your opinion is different<br />
                        or your type of humor isn't conforming to the social norms?<br /><br />
                        By asking this question we are not saying that we will allow hate speech, praise of terrorism<br />
                        or graphic violence being shared on our platform.<br /><br />
                        We understand that is impossible to have a fully decentralised social media platform<br />
                        in order to mantain a good vibe on our social network.<br /><br />
                        However, we promise that we are going to try to be the most decentralised as its possible<br />
                        and allow people to write out all of their thoughts, make any kind of jokes and post<br />
                        everything they want to as long as it doesn´t touch on the points mentioned before. </p>
                </div>
                <p className='title'>LET´S HEAR THOSE VOICES OF WEB3!</p>
                <img className='line' src='./line.png'></img>
                <p className='title'>WHAT IS LG DAO?</p>
                <img className='hodllogowp' src='./lgdao.png'></img>
                <div className='text-box-wp'>
                    <p>It’s La galerie's DAO that will earn a percentage of all the start ups that we are developing<br />
                        (HODL included).<br /><br />
                        If I can buy a Le Bel Anus NFT and belong to LG DAO, why should I buy a Meta Fokin Blinder?<br /><br />
                        It’s a cheaper entry point into the LG DAO for those who have not had a chance to buy<br />
                        a Le Bel Anus NFT when we minted.<br /><br />
                        SUMMARY:<br /><br />
                        - Meta “FOKIN” Blinders will bring you HODL. The social media that will change<br />
                        the game on the web3 space.<br /><br />
                        It doesn’t matter if MFB sells out (which it will).<br /><br />
                        HODL will come to life at the end of September (we invested the money upfront)<br />
                        even if we mint 0 NFTs.<br /><br />
                        - Minting Meta “Fokin” Blinders and staking them will get you LG points. With those<br />
                        points you will get access to LG DAO and get a % from royalties of all the start ups<br />
                        that La Galerie is building. Later those points will be traded for LG tokens.</p>
                </div><br />
                <a className='button' href='https://www.lagaleriedesnft.com/tokenomics'>TOKENOMICS</a><br />
                <div className='text-box-wp'>
                    <p>- WE BUILD AND WE DELIVER.<br /><br />
                        Our first collection’s roadmap was delivered on schedule, and we even added a bit of extra on<br />
                        top- a full blown party with famous Adult Entertainment stars only for our holders.<br /><br />
                        - LA GALERIE IS HERE TO STAY.<br /><br />
                        Our founder’s mindset is different.<br /><br />
                        Tiago Paiva prioritises the holders wellbeing first, the web3 space’s development second,<br />
                        and only then on the profits. Making already a successful web2 career, he is fully dedicated<br />
                        to see his web3 project being delivered to full fruition by the team, satisfying the holders’<br />
                        expectations and improving the space as a whole.<br /><br />
                        HOPE YOU LIKED OUR WHITE PAPER.</p>
                </div>
                <p className='title'>IF YOU DIDN’T “FOK” OFF :)</p>
                <img className='line' src='./line.png'></img><br /><br />
                <div className='text-box-wp'>
                    <p>JOIN US ON DISCORD, GET UP TO ALL THE NEWS <br /> AND TO BE INVOLVED IN OUR DAO'S DECISIONS</p>
                </div>
                <img className='discordlogo' src='./discordlogo.png'></img><br />
                <a className='button' href='https://discord.com/invite/9uX2wbbzG9' target="_blank">JOIN US ON DISCORD</a><br />


            </Main >
            <Footer className="bg-solid-color footer">
                    <div className="logos">
                    <a href="https://jewels.lagaleriedesnft.com/" target="_blank">  <img src="./lglogo.png" alt="" /></a>
                        <a href="https://www.lagaleriedesnft.com/lba" target="_blank"> <img className="lbalogomini" src="./lbalogo.png" alt="" /></a>
                       <a href="https://www.lagaleriedesnft.com/mfb" target="_blank"> <img src="./mfblogomini.png" alt="" /></a>
                        <img src="./nmvlogo.png" alt="" />
                        <img src="./mntlogo.png" alt="" />
                        <img src="./hodllogo.png" alt="" />
                    </div>


                </Footer>
                <div className="copyright">
                    <p>2022 La Galerie Des NFT - All rights reserved.</p>
                </div>

        </div >

    );
}

export default WhitePaper;