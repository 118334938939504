import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./banner.scss";


import { BannerImages } from "./data";

const Banner = () => {
  useEffect(() => {
    AOS.init({});
  }, []);
  return (
    <>
      <section className="banner">
        <div className="container">
          <div className="main_img">
            {BannerImages.map((img, i) => {
              return <img key={i} style={{ zIndex: `${99 - i}` }} src={img} />;
            })}
          </div>
        </div>
        <div className="mfblogo" data-aos="fade-up" > <img src="/mfblogo.png"></img> </div>
      </section>
    </>
  );
};

export default Banner;
