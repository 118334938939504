
export const nav = [
  {
    name: "Roadmap",
    path: "#roadmap",
  },
  {
    name: "About Us",
    path: "#about",
  },
  {
    name: "Our Team",
    path: "#team",
  },
  {
    name: "FAQs",
    path: "#faq",
  },
  {
    name: "Tokenemonics",
    path: "#tech",
  },
  {
    name: "Staking",
    path: "#staking",
  },
];

export const team = [
  {
    name: "Tiago Paiva",
    position: "Founder",
    path: "/mfb/images/Personagens_bg/Thomas_street.webp",
    twitter: "https://twitter.com/Tiagopaiva__",
  },
  {
    name: "Richie",
    position: "Project Manager",
    path: "/mfb/images/Personagens_bg/John.webp",
    twitter: "https://twitter.com/ricardodtleite",
  },
  {
    name: "Afonsus",
    position: "Community Manager",
    path: "/mfb/images/Personagens_bg/Arthur.webp",
    twitter: "https://twitter.com/afonsusss",
  },
  {
    name: "goat",
    position: "Strategy Manager",
    path: "/mfb/images/Personagens_bg/Alfie.webp",
    twitter: "https://twitter.com/AndrCouto2",
  },
  {
    name: "Rudy",
    position: "Marketing Manager",
    path: "/mfb/images/Personagens_bg/John_street.webp",
    twitter: "https://twitter.com/R0UDEY",
  },
  {
    name: "And a team of 20+ MODs",
    position: "Discord Management",
    path: "/mfb/images/Personagens_bg/Thomas.webp",
  },
];

export const roadmap = [
  {
    id: "01",
    points: [
      "Marketing on all social media platforms.",
      "Grand opening of our Discord to the public.",
      "Collaborations with VIP influencers and DAOs.",
      "Sneak peeks!",
      "Roadmap Release.",
    ],
    imgUrl: "/mfb/images/Personagens_bg/Alfie.webp",
  },
  {
    id: "02",
    points: ["Official launch of 5555 NFTs from the MFB Collection.",
    "Listings on secondary markets.",
    "MFB holders become shareholders of all LG startups.",
    "A % of the LG startups will revert to the LG DAO wallet",
  ],
    imgUrl: "/mfb/images/Personagens_bg/Arthur.webp",
  },
  {
    id: "03",
    points: ["Creation of a staking system to stake LG DAO Points",
    "Stake an MFB to start yielding LG DAO Points. The points will be converted to the $LG token once it is released",
  ],
    imgUrl: "/mfb/images/Personagens_bg/thomas_hat.webp",
  },
  {
    id: "04",
    points: ["Utilities of $LG are announced.",
    "Raffles and an auction mechanism for numerous items (NFTs, whitelists, merch, etc) for the LG DAO",
  ],
    imgUrl: "/mfb/images/Personagens_bg/John.webp",
  },
  {
    id: "05",
    points: [
    "Announcement of V2 Roadmap.",
  ],
    imgUrl: "/mfb/images/Personagens_bg/thomas_cigar.webp",
  },
];
export const BannerImages = [
  "/alfie.png",
  "/arthur.png",
  "/thomas.png",
  "/john.png",
];
export const nft = [
  "/mfb/images/Personagens_bg/Arthur.webp",
  "/mfb/images/Personagens_bg/Thomas.webp",
  "/mfb/images/Personagens_bg/Alfie.webp",
  "/mfb/images/Personagens_bg/John_street.webp",
  "/mfb/images/Personagens_bg/Thomas_street.webp",
  "/mfb/images/Personagens_bg/John.webp",
];

